import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   status: false,
   title: '',
   content: '',
   response: 'success',
};

export const toastSlice = createSlice({
   name: 'modal',
   initialState,
   reducers: {
      setHandleStatusToast: (state, action) => {
         const { status, title, content, response } = action.payload;
         return {
            ...state,
            status,
            title,
            content,
            response,
         };
      },
      resetHandleStatusToast: () => initialState,
   },
});

export const { setHandleStatusToast, resetHandleStatusToast } =
   toastSlice.actions;
export default toastSlice.reducer;
