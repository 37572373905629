type Props = {
   isWhite?: boolean;
};

const LoadingIcon = ({ isWhite }: Props) => {
   const svgCode = isWhite
      ? `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38"><defs><linearGradient id="a" x1="8.042%" x2="65.682%" y1="0%" y2="23.865%"><stop offset="0%" stop-color="#fff" stop-opacity="0"/><stop offset="63.146%" stop-color="#fff" stop-opacity=".631"/><stop offset="100%" stop-color="#fff"/></linearGradient></defs><g fill="none" fillRule="evenodd" transform="translate(1 1)"><path stroke="url(#a)" stroke-width="2" d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" dur="0.9s" from="0 18 18" repeatCount="indefinite" to="360 18 18" type="rotate"/></path><circle cx="36" cy="18" r="1" fill="#fff"><animateTransform attributeName="transform" dur="0.9s" from="0 18 18" repeatCount="indefinite" to="360 18 18" type="rotate"/></circle></g></svg>
  `
      : `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38"><defs><linearGradient id="a" x1="8.042%" x2="65.682%" y1="0%" y2="23.865%"><stop offset="0%" stop-color="#aab1c7" stop-opacity="0"/><stop offset="63.146%" stop-color="#aab1c7" stop-opacity=".631"/><stop offset="100%" stop-color="#aab1c7"/></linearGradient></defs><g fill="none" fillRule="evenodd" transform="translate(1 1)"><path stroke="url(#a)" stroke-width="2" d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" dur="0.9s" from="0 18 18" repeatCount="indefinite" to="360 18 18" type="rotate"/></path><circle cx="36" cy="18" r="1" fill="#5b5b6d"><animateTransform attributeName="transform" dur="0.9s" from="0 18 18" repeatCount="indefinite" to="360 18 18" type="rotate"/></circle></g></svg>
  `;

   return <div dangerouslySetInnerHTML={{ __html: svgCode }}></div>;
};

export default LoadingIcon;
